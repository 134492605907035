.link-primary {
    color: $primary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $hover;
    }
}

.link-secondary {
    color: $primary;
    font-family: $hand-font;
    font-size: 1.2em;
    font-weight: normal;
    text-decoration: underline;
    background-color: transparent;

    .svg-icon {
        margin-left: .8rem;
    }

    &:hover {
        color: $hover;

        .svg-icon {
            fill: $hover;
        }
    }
}
    .link-secondary--icon,
    .link-secondary--svg {
        position: relative;
        text-decoration: none; 
    }

    .link-secondary--more,
    .link-secondary--icon-first {
        position: relative;
        .svg-icon {
            margin-left: 0;
            position: absolute;
            left: 0;
        }
    }

    .link-secondary--more {
        padding-left: 2rem;
        display: inline-block;
        text-decoration: underline;
        font-size: $font-size-xxxl;

        .svg-icon {
            top: .7rem;
        }
    }
    .link-secondary--icon-first {
        padding-left: 3.2rem;
        display: inline-block;
        text-decoration: underline;
        .svg-icon {
            top: 0;
        }
    }

    .link-secondary--small {
        font-size: $font-size-xl;
        .svg-icon {
            top: .4rem;
        }
    }

.link-flat {
    color: $body-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    span {
        color: $primary;
    }
}
    .link-flat--icon {
        position: relative;
        display: block;
        padding: $grid-gap*.5 0 $grid-gap*.5 3.6rem;

        .svg-icon {
            position: absolute;
            top: 1.3rem;
            left: 0;
        }
            .svg-icon--end {
                top: 1.5rem;
                left: auto;
                right: 0;
            }
    }
    .link-flat--readmore {
        position: relative;
        display: block;
        padding: $grid-gap*.5 4rem $grid-gap*.5 0;

        .svg-icon {
            position: absolute;
            top: 50%;
            right: 0;
            left: auto;
            transform: translateY(-50%);
        }
    }

.icon-info {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.link-more-info {
    color: $hover;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: $hover-dark;
        text-decoration: underline;
    }
}
