// Icons v2

.svg-icon {
    height: 2.4rem;
    width: 2.4rem;
}
    // sizes
    .svg-icon--xs,
    .svg-icon--s {
        fill: $primary;
    }
    .svg-icon--xs {
        height: 1.6rem;
        width: 1.6rem;
    }
    .svg-icon--s {
        height: 2.4rem;
        width: 2.4rem;
    }
    .svg-icon--m,
    .svg-icon--l,
    .svg-icon--xl
    {
        fill: $primary;
    }
    .svg-icon--m {
        height: 3.2rem;
        width: 3.2rem;
    }
    .svg-icon--l {
        height: 4rem;
        width: 4rem;
    }
    .svg-icon--xl {
        height: 6rem;
        width: 6rem;
    }

    .svg-icon--outlined
    {
        fill: none;
        stroke: $primary;
        stroke-width: 0.1rem;
    }


// colors
    .svg-icon--secondary {
        fill: $secondary;
    }
    .svg-icon--dark {
        fill: $headline-dark;
    }
