.dropup {    
    bottom: 100%;
    top: inherit;
    &:before {
        top: inherit;
        bottom: -2.2rem;
        transform: rotate(180deg);
    }

    &:after {
        top: inherit;
        bottom: -1.9rem;
        transform: rotate(180deg);
    }
}

.dropup--right {
    left: inherit;
    right: 0;
    &:before {
        top: inherit;
        bottom: -2.2rem;
        right: 2.4rem;
        left: initial;
        transform: rotate(180deg);
    }

    &:after {
        top: inherit;
        bottom: -1.9rem;
        right: 2.5rem;
        left: initial;
        transform: rotate(180deg);
    }
}

.dropup--center {
    left: 50%;
    transform: translate(-50%);

    &:before {
        left: 50%;
        top: 10.5rem;
        transform: rotate(180deg);
        margin-left: -1.1rem;
    }

    &:after {
        left: 50%;
        top: 10.4rem;
        transform: rotate(180deg);
        margin-left: -1rem;
    }
}