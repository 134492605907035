.text {}
    .text--line-through {
        text-decoration: line-through;
    }

    .text--block {
        display: block;
    }

    .text--center {
        text-align: center;
    }

    .text--strong {
        font-weight: 700;
    }

    .text--primary {
        color: $primary;
    }

    .text--secondary {
        color: $hover;
    }

    .text--no-wrap {
        white-space: nowrap;
    }

    .text--underline {
        text-decoration: underline;
    }

.supporting-text {
    margin-bottom: 4rem;
}

.intro-text {
    font-family: $font-family-sans-medium;
    font-size: $intro-font-size;
    line-height: 1.5em;
    color: $secondary-dark;
    @media (max-width: $screen-sm-max) {
        font-size: $font-size-small-rem;
    }
}
