.radio-list {
    display: flex;
    flex-flow: column wrap;
    margin-right: -15px;
    margin-left: -15px;
}
    .radio-list--half {
        flex-direction: row;
        .radio-list__item {
            flex: 50%;
        }
    }

    .radio-list__item {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        input[type=radio] + label {
            display: inline-flex;
            margin-bottom: 0;
            padding-left: 3rem;
            padding-right: 1.6rem;
            &:before {
                top: 0;
            }
            &:after {
                top: .4rem;
            }
        }
    }

    .radio-list__item--centered {
        align-items: center;
        input[type=radio] + label {
            align-items: center;
            &:before {
                top: inherit;
            }
            &:after {
                transform: translateY(-50%);
                top: 50%;
            }
        }
    }

    .radio-list__product {
        margin-top: -1rem;
        margin-right: 1.5rem;
    }
    .radio-list__info {
        margin-left: auto;
        &:hover {
            text-decoration: none;
        }
    }
    .radio-list__extra-info {
        position: absolute;
        right: 0;
        flex-shrink: 0;
        cursor: pointer;
    }
    .radio-list__content {
        background-color: $secondary-light3;
        padding: 20px;
        margin-left: 38px;
        margin-top: 10px;
    }
