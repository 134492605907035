.show-password__icon {
    height: 2rem;
    width: 2rem;
    position: absolute;
    right: 4rem;
    top: 11px;
    cursor: inherit;

    a {
        width: 2rem;
        height: 2rem;
    }
}