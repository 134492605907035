@import 'settings/variables'; // Temporary, otherwise variables will come from old library

html {
    font-size: 62.5%;
    text-rendering: optimizeLegibility;
}

body {
    background-color: $secondary-light3;
    font-family: $font-family-sans-serif;
    font-size: $font-size;
    line-height: 1.42;
    color: $body-color;
    -webkit-text-size-adjust: 100%;

    &:before {
        content: "xs";
        display: none;
        /* Prevent from displaying. */
        @media (min-width: $screen-sm-min) {
            content: "sm";
        }
        @media (min-width: $screen-md-min) {
            content: "md";
        }
        @media (min-width: $screen-lg-min) {
            content: "lg";
        }
        @media (min-width: $screen-xl-min) {
            content: "xl";
        }
    }

    @media (max-width: $screen-md-max) {
        // Safari IOS11 input fix
        &.modal-open {
            position: fixed;
            width: 100%;
        }
    }
}
