.block {
    position: relative;
    padding: $grid-gap;
    margin-bottom: $grid-gap;
    background-color: $white;
    
    img {
        max-width: 100%;
    }
}

    .block__content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media screen and (min-width: $screen-md-min) {
            flex-direction: row;
        }
    }

    .block__content-inner {
        display: inline-block;
        vertical-align: top;
    }    

        .block__content-inner--left {
            flex: 1;
            padding-right: 3rem;
        }