.checkbox-group {
    & > .form-group {
        min-height: auto;
        margin-bottom: 0;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid $secondary-light2;

        input[type=checkbox] + label {
            min-height: auto;
            margin-right: 0;
            padding-right: 0;
        }
    }

    .control-input {
        &:after {
            display: none;
        }
    }
}
    .checkbox-group__item {
        display: block;
        min-height: auto;
        margin-bottom: 0;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        border-bottom: 1px solid $secondary-light2;

        input[type=checkbox] + label {
            min-height: auto;
        }

        .form-group {
            min-height: auto;
        }
        .control-input,
        .additional-coverage__linked {
            margin-bottom: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }