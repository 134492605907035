.alert-well {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px 15px 70px;
    h5 {
        font-size: 1em;
    }
    h5,
    p {
        padding-left: 0;
    }
    .icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
    @media(max-width: $screen-sm-max) {
        padding: 15px 25px;
        justify-content: flex-start;
        flex-direction: column;
        svg {
            display: none;
        }
    }
}

    .alert__buttons {
        margin-left: 20px;
        align-self: center;
        @media (max-width: $screen-sm-max) {
            align-self: flex-start;
            margin-left: 0;
            margin-top: 10px;
        }
    }
