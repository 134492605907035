.alert-success{
    padding-left: 4rem;
    background-color: $white;
    border-color: $border-color-dark;
    border-radius: $border-radius-half;

    h5,
    p {
        color: $body-color;
    }

    .svg-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
    }
}
