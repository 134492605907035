.line {
	display: block;
	margin: 0;
	height: 5px;
	width: 6.5rem;
	display: inline-block;
    border-bottom: 2px solid $primary;
}
	.line--underline {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		line-height: 1px;
		overflow: hidden;
	}
	.line--amount {
		margin-left: 1rem;
	}
	.line--total {
		position: relative;
		width: 100%;
		height: .7rem;
	}
	.line--total-plus {
		position: relative;
		width: calc(100% - 4.5rem);
		height: .7rem;

		&:after {
			content: '';
			width: 2.4rem;
			height: 2.4rem;
			position: absolute;
			right: -3.5rem;
			top: -1rem;

			background-image: url($svg_plus_primary);
			background-repeat: no-repeat;
			background-size: 2.4rem;
		}
	}
	.line-dotted {
		background: none;
		border: 0;
		height: .8rem;
		line-height: .1rem;
		overflow: hidden;
		width: 100%;
		
		&:after {
			color: $secondary-light2;
			content: "..................................................................................................................................................................................";
			display: block;
			font-size: 2.55rem;
			height: 1rem;
			letter-spacing: .4rem;
		}
	}