.image-container {
    position: relative;
	z-index: 1;
}

.image {
	max-width: 100%;
}
	.image--space {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.image--space-all {
		padding: 2rem;
	}

	.image--right {
		display: block;
		margin-left: auto;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.image--block {
		display: block;
	}
	.image--hidden-desktop {
		display: block;
		@media (min-width: $screen-md-min) {
			display: none;
		}
	}
	.image--hidden-tablet-portrait {
		display: none;
		@media (min-width:$screen-lg-min) {
			display: block;
		}
	}
	.image--hidden-tablet {
		display: block;
		@media (min-width: $screen-lg-min) {
			display: none;
		}
	}
	.image--hidden-mobile {
		display: none;
		@media (min-width: $screen-md-min) {
			display: block;
		}
	}
	.image--floating {
		position: absolute;
		bottom:-2.8rem;
		right: 46.2%;
		max-height: 11rem;
	}

	.image--bottom {
		margin-bottom: -0.6rem;

		@media (max-width: $screen-md-min) {
			margin-top: 1rem;
		}
	}
