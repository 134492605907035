//////////////////////////////////////////////////
// COLORS
//////////////////////////////////////////////////

// MAIN
$white:                 #FFFFFF;
$black:                 #000000;

$primary:               #F173AC;
$primary-dark:          darken($primary, 10%); //#EC4591
$primary-light:         #F3A0C4;
$primary-light2:        #F9C7DE;
$primary-light3:        #FCE3EE;
$primary-shadow:        #BF417A;

$hover:                 #0D7FF4;
$hover-dark:            darken($hover, 10%); //#0966c5

$headline-dark:         #222222;
$headline-light:        #FFFFFF;

$secondary:             #888780;
$secondary-dark:        #585750;
$secondary-light:       #B8B7B3;
$secondary-light2:      #DADADA;
$secondary-light3:      #F6F6F6;
$secondary-light4:      #E3DED9;

$euro-blue:             #002EEA;
$nl-color:              #FFBF00;
$de-color:              #0F7EF3;

$body-color:            $secondary-dark;
$shadow-color:          $secondary-light;
$button-hover:          #FAFAFA;
$button-disabled:       #DDDDDD;

$skeleton-light:        #E8E8E8;
$skeleton-dark:         #DDDDDD;
$skeleton-darker:       #CCCCCC;

// NEWS COLORS
$news-color1:           #CCCCCC;
$news-color2:           #999999;

$gravy-grey:            #5b5a54;

// BORDERS
$border-color:          $secondary-light2;
$border-color-dark:     $secondary-light2;

$button-border-color:   #CCCCCC;

$border-radius: 10px;
$border-radius-big: 16px;
$border-radius-half: 5px;
$border-radius-inputs: 3px;

$border-width: 2px;

//////////////////////////////////////////////////
// FONTS
//////////////////////////////////////////////////

// FONT FAMILIES
$font-family-sans-serif: "inshared_sans_regular", sans-serif; // Figma weight 400
$font-family-sans-medium: "inshared_sans_medium" , sans-serif; // Figma weight 500
$headings-font-family: "inshared_sans_bold", sans-serif; // Figma weight 700
$hand-font: "casino_hand", serif;

// FONT SIZES
$font-size: 17px; // only for body font
$font-size-small: 16px; // mobile font size


// TODO: Terugbrengen naar minder font-sizes
$font-size-xxxxs: 1.2rem;
$font-size-xxxs: 1.3rem;
$font-size-xxs: 1.4rem;
$font-size-xs: 1.5rem;
$font-size-s: 1.6rem;
$font-size-m: 1.7rem;
$font-size-l: 1.8rem;
$font-size-xl: 1.9rem;
$font-size-xxl: 2rem;
$font-size-xxxl: 2.2rem;
$font-size-xxxxl: 2.5rem;

$font-size-rem: $font-size-m;
$font-size-small-rem: $font-size-s;

$h1-font-size: 3.1rem;
$h2-font-size: 2.4rem;
$h3-font-size: $font-size-xxl;
$h4-font-size: $font-size-m;

$h1-font-size-small: $font-size-xxxxl;
$h2-font-size-small: $font-size-xxxl;
$h3-font-size-small: $font-size-xl;
$h4-font-size-small: $font-size-s;

$h1-font-size-xl: 6.2rem;
$h1-font-size-xl-small: 3.4rem;

$intro-font-size: $font-size-xl;

// $link-color: $secondary;
$link-hover-color: $hover;
$link-decoration: underline;
$link-hover-decoration: underline;

//////////////////////////////////////////////////
// BREAKPOINTS
//////////////////////////////////////////////////

$screen-xxs-max: 320px;
$screen-sm-min: 576px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 768px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 992px;
$screen-md-max: ($screen-lg-min - 1);
$screen-xl-min: 1200px;
$screen-lg-max: ($screen-xl-min - 1);

// variables for specific mediaqueries
$tablet-screen-up: 1024px;
$tablet-screen-down: ($tablet-screen-up - 1);

$mobile-header-img-max: 415px;
$tablet-header-img-min: 416px;

$modal-full-max: 600px;
$modal-small-min: 601px;

//////////////////////////////////////////////////
// GRID
//////////////////////////////////////////////////

$grid-gap: 30px;
$flex-gutter: 30px;

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;

//////////////////////////////////////////////////
// ICONS
//////////////////////////////////////////////////

$svg-icon-xs: 16px;
$svg-icon-s: 24px;
$svg-icon-m: 32px;
$svg-icon-l: 40px;
$svg-icon-xl: 60px;
