// .cta-button {}
    .cta-button__label {
        position: relative;
        margin-top: 13px;
        margin-left: 32px;
        padding-left: 32px;
        font-family: $hand-font;
        font-size: 21px;
        color: $primary;
        svg {
            position: absolute;
            left: 0;
            margin-right: 5px;        
            fill: currentColor;
        }
    }