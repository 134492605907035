@import 'settings/variables'; // Temporary, otherwise variables will come from old library

/*
 *  Default btn
 */
.btn {
    display: inline-block;
    margin-bottom: .3rem;
    padding: 1.3rem 1.6rem .9rem;
    cursor: pointer;
    white-space: initial;
    line-height: initial;
    border: solid 2px transparent;
    font-size: $font-size-xs;
    font-family: $headings-font-family;
    font-weight: 700;
    text-transform: none;
    text-decoration: none;
    box-shadow: 3px 3px 0 0 $shadow-color;
    color: $headline-dark;
    transform: translate(0, 0);
    transition: all ease .2s;

    &:focus {
        outline: initial;
    }

    &:active,
    &:active {
        box-shadow: none;
    }

    &:focus,
    &:hover {
        color: $white;
        background-color: $hover;
        text-decoration: none;
    }

    &.disabled,
    &:disabled,
    &[disabled] {
        color: $shadow-color;
        cursor: not-allowed;
        opacity: .65;
        box-shadow: none;

        &:hover {
            background-color: buttonface;
        }
    }
    
    @media (max-width: $screen-xs-max) {
        white-space: inherit;
    }

    @media (max-width: $screen-xs-max) {
        white-space: inherit;
    }
}
    .btn--prev {
        padding-left: 0;
        padding-right: 0;
        line-height: 1.375em;
        text-decoration: underline;
        font-family: $hand-font;
        font-weight: 400;
        font-size: $font-size-xxxl;
        box-shadow: none;
        color: $primary;
        background-color: transparent;

        &:focus,
        &:hover {
            text-decoration: none;
            color: $hover;
            background-color: transparent;
        }

        .svg-icon {
            margin-right: .8rem;
        }
    }

    /*
    *  Primary btn
    *  .btn btn--primary
    */

    .btn--primary {
        min-height: 4.8rem;
        padding: 1.3rem 2.4rem .9rem;
        background-color: $primary;
        border-radius: 3px;
        box-shadow: 3px 3px 0 0 $primary-shadow;
        text-align: center;
        font-size: $font-size-rem;
        color: $white;
        &:hover,
        &:focus {
            background-color: $hover;
            border-color: transparent;
            box-shadow: 3px 3px 0 0 $hover-dark;
        }
        &:active {
            transform: translate(3px, 3px);
            background-color: $hover-dark;
            border-color: transparent;
            box-shadow: none;
        }

        &.disabled,
        &:disabled,
        &[disabled] {
            opacity: 1;
            background-color: $secondary-light2;
            border-color: $secondary-light2;
            color: $secondary-dark;
            box-shadow: 3px 3px 0 0 $secondary-light;
            &:hover {
                border-color: $secondary-light2;
                background-color: $secondary-light2;
            }
            &:active {
                transform: none;
            }
        }


        @media (max-width: $screen-sm-max) {
            font-size: $font-size-s;
        }
    }

    /*
    *  Secondary btn
    *  .btn btn--secondary
    */

    .btn--secondary {
        padding: 1.4rem 2.4rem 1rem;
        border-radius: 3px;
        box-shadow: 3px 3px 0 0 $secondary-light;
        background-color: $white;
        text-align: center;
        font-size: $font-size-rem;
        border-color: $secondary-light;
        color: $headline-dark;

        &:hover,
        &:focus {
            border-color: $secondary-light;
            color: $primary-dark;
            background-color: $white;
            
            &.btn--arrow-right {
                .btn__icon {
                    fill: $primary-dark;
                    transition: all ease .2s;
                }
            }
        }
        &:active {
            transform: translate(3px, 3px);
            color: $primary-shadow;

            &.btn--arrow-right {
                .btn__icon {
                    fill: $primary-shadow;
                    transition: all ease .2s;
                }
            }
        }

        &.disabled,
        &:disabled,
        &[disabled] {
            opacity: 1;
            box-shadow: 3px 3px 0 0 $secondary-light;
            background-color: $white;
            color: $secondary;
            &:hover {
                background-color: $white;
                color: $secondary;
            }
            &:active {
                transform: none;
            }
            &.btn--arrow-right {
                .btn__icon {
                    fill: $secondary;
                }
            }
        }
    }

    .btn--sm {
        min-height: 3.3rem;
        font-family: $headings-font-family;
        font-size: $font-size-xxxs;
        padding: .9rem .8rem .5rem .8rem;
    }

    .btn--dropdown {
        position: relative;
        padding-right: 4rem;

        .btn__icon {
            position: absolute;
            right: 1.6rem;
            top: 50%;
            transform: translateY(-50%);
            fill: currentColor;
            pointer-events: none;
        }
        .btn__icon-left {
            display: inline-block;
            position: absolute;
            left: 2rem;
            top: .8rem;
            pointer-events: none;
        }
    }

    .btn--icon-left {
        padding-left: 5.4rem;
    }

    .btn--floating-arrow {
        margin-top: 3rem;
        .floating-arrow__icon {
            @media (max-width: 320px) {
                display: none;
            }
        }
    }

    .btn--link {
        padding-left: 0;
        padding-right: 0;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        font-family: $hand-font;
        font-size: $h3-font-size;
        font-weight: 400;
        color: $primary;
        cursor: pointer;
    
        &:hover,
        &:focus {
            color: $hover;
            background: transparent;
        }
    }

    /*
    *  More btn
    *  .btn btn--more
    */

    .btn--more {
        padding: 0;
        padding-right: 3rem;
        display: inline-block;
        position: relative;
        z-index: 1;
        cursor: pointer;
        background-color: transparent;
        box-shadow: none;
        font-size: $font-size-xxl;
        font-family: $hand-font;
        text-decoration: underline;
        font-weight: 400;
        color: $primary;

        &:hover,
        &:focus {
            color: $hover;
            text-decoration: none;
            background-color: transparent;

            .svg-icon {   
                fill: $hover;
            }
        }

        &:focus {
            background-color: transparent;
            color: $hover;
        }

        &.disabled {
            color: $primary;

            &:hover,
            &:focus {
                background-color: transparent;
                color: $primary;
                text-decoration: underline;
            }

            &:focus {
                color: $hover;
            }
        }

        .svg-icon {
            position: absolute;
            right: 0;
            top: .5rem;
        }
    }

    /*
    *  Icon btn
    *  .btn btn--icon > .icon
    */
    .btn--icon {
        padding: 0;
        background: none;
        box-shadow: none;
        border: none;

        &:focus,
        &:hover {
            color: $hover;
            background-color: transparent;

            .icon {
                color: $hover;
            }
        }

        &.disabled {
            &:hover {
                background-color: transparent;

                .icon {
                    color: $primary;
                }
            }
        }
        // weghalen als buttons zijn omgezet
        .icon {
            color: $primary;
        }
        //
    }

    /*
    *  Close btn
    *  .btn btn--close > .icon
    */
    .btn--close {
        padding: 0;
        background-color: transparent;
        color: $secondary;
        line-height: 1;
        box-shadow: none;

        &:focus,
        &:hover {
            background-color: transparent;
            color: $hover;
        }

        &.disabled {
            &:hover {
                background-color: transparent;
                color: inherit;
            }
        }
    }

    .btn--block {
        display: block;
        width: 100%;
    }

    .btn--right {
        float: right;
    }

    .btn--arrow-right {
        position: relative;
        padding-right: 4rem;
        .btn__icon {
            position: absolute;
            right: .8rem;
            top: 50%;
            transform: translateY(-50%);
        }

        &.btn--primary {
            .btn__icon {
                fill: $white;
                transition: all ease .2s;
            }
        }
        &.btn--secondary {
            .btn__icon {
                fill: $headline-dark;
                transition: all ease .2s;
            }
        }
        &.disabled,
        &:disabled,
        &[disabled] {
            .svg-icon {
                fill: $secondary-dark;
            }
        }
    }

    .btn--light {
        background-color: $secondary-light2;
        border-color: $secondary-light2;
        color: $secondary-dark;
        box-shadow: 3px 3px 0 0 $secondary-light;
        border-radius: 3px;

        &:hover,
        &.active {
            background-color: $primary;
            border-color: $primary;
            box-shadow: 3px 3px 0 0 $primary-shadow;
            color: $white;
        }
    }

.btn-sublink {
    margin-top: .8rem;
    margin-bottom: 0;
}

.btn-label-arrow {
    position: absolute;
    top: -1.5rem;
    left: 10rem;
    color: $primary;
    font-family: $hand-font;
    font-weight: 400;
    font-size: $h3-font-size;
}

// End
