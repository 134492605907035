.alert {
    position: relative;
    padding: 1.6rem;
    margin-bottom: 2.4rem;
    border: 1px solid transparent;
    font-size: 1.6rem;
    white-space: initial;

    p {
        margin: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: baseline;

            > svg {
                flex-shrink: 0;
            }
        }
    }

    .btn--close {
        position: absolute;
        right: .5rem;
        top: 1rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
    // Alert validation
    .alert--validation,
    .alert--warning,
    .alert--information {
        margin-top: 1.6rem;
        padding-left: 4rem;
        position: relative;
        border: 2px solid $primary;
        border-radius: $border-radius-big;
        background-color: $white;
        color: $secondary;
        text-align: left;

        @extend %arrow-top-left;
    }

    .alert--warning {
        padding-left: 1.6rem;
        border-style: dashed;

        &:before {
            border-style: double;
            border-width: 2px 8px 12px 8px;
        }

        &:after {
            top: -9px;
        }
    }

    .alert--information {
        margin-bottom: 1.6rem;
        margin-top: .8rem;
        padding-left: 1.6rem;
        &:last-child {
            margin-bottom: 1.6rem;
        }
    }

    // Alert arrow position
    .alert--center {
        &:before {
            left: 50%;
            transform: translateX(-50%);
        }
        &:after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .alert--right {
        &:before,
        &:after {
            right: 1.8rem;
            left: initial;
        }
        &:after {
            right: 2rem;
        }
    }

    .alert--no-arrow {
        &:before, &:after {
            content: none;
        }
    }

    .alert__icon {
        position: absolute;
        left: 1.6rem;
    }
