$widthValidation: 2.7rem;

.form-control {
    width: calc(100% - #{$widthValidation});
    min-height: 4rem;
    padding: .4rem 1.2rem 0 1.2rem;
    line-height: 1.4;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: $border-radius-inputs;
    border: 1px solid $secondary-light;
    box-shadow: none;
    background-color: $white;
    color: $secondary-dark;
    font-family: $font-family-sans-serif;
    font-size: $font-size-rem;

    transition-property: border-color;
    transition-duration: .15s;
    transition-timing-function: ease-in-out;

    &[disabled] {
        background-color: $secondary-light3;
        color: $secondary;
        -webkit-text-fill-color: $secondary;
        opacity: 1;
    }

    &::placeholder {
        color: $secondary-light;
    }

    &:-ms-input-placeholder {
        color: $secondary-light;
    }

    &::-ms-input-placeholder {
        color: $secondary-light;
    }

    &:focus {
        border-color: $primary-dark;
        outline: none;
    }

    &[type=email] {
        text-transform: lowercase;
    }
    &[type=checkbox], 
    &[type=radio] {
        position: absolute;
        left: -999em;
    }
}

    .control-input .ng-invalid .form-control--dropdown {
        color: $secondary-light;
    }

    .form-control--dropdown {
        padding-right: 3.2rem;

        option {
            color: $secondary-dark;
        }

        option:disabled {
            color: $secondary-light;
        }
    }
    .form-control--half {
        margin-right: .8rem;
        width: calc((100% - #{$widthValidation}) / 2 - .4rem);
        &:last-child {
            margin-right: 0;
        }
    }
    .form-control--transparent {
        border-color: transparent;
        padding-left: 0;
        padding-top: 0;

        &[disabled] {
            background-color: transparent;
        }
    }
    .form-control--change {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }