.svg-icon {
    .primary-color {
        fill: $primary;
    }
    .primary-border {
        outline: $primary;
    }
}

.icon-eye,
.icon-eye-slash {
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-eye {
    display: inline-block;
    background-image: url(/assets/shared/icons/eye-open.svg);
}

.icon-eye-slash {
    display: inline-block;
    background-image: url(/assets/shared/icons/eye-closed.svg);
}
