a {
    color: $primary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    p & {
        color: currentColor;
        text-decoration: underline;
        &:hover {
            color: $hover;
        }
    }
}