.control-label {
    position: relative;
    width: 45%;
    display: inline-block;
    padding-right: 1rem;

    @media (max-width: $screen-xs-max) {
        width: 100%;
        padding-right: 0;
        margin-bottom: .5rem;
    }
}
    .control-label__inner {
        min-height: 4rem;
        display: flex;
        align-items: center;
        white-space: initial;
        @media (max-width: $screen-xs-max) {
            min-height: auto;
        }
    }

    .control-label__info {
        @media (min-width: $screen-sm-min) {
            position: absolute;
            max-width: calc(100% - 2.5rem );
            z-index: 1;
        }
    }
