.select-wrapper {
	position: relative;

	&:after {
		content: '';
		width: 4rem;
		position: absolute;
		right: 5.9rem;
		z-index: 2;
		top: .1rem;
		height: 3.8rem;
		background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		pointer-events: none;
	}
}
    .select-wrapper--disabled {
		&:after {
			background: none;
		}
	}

select {
    appearance: none;
    -webkit-appearance: none;
    -webkit-user-select: none;
    height: 4rem;
    border-radius: 0;
    background-image: url($svg_arrow_down_primary);
    background-repeat: no-repeat;
    background-size: 1.4rem;
    background-position: calc(100% - 1rem) 50%;
}
    select::-ms-expand {
        display: none;
    }