.dropdown-container {
    position: relative;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 160;
    display: none;
    min-width: 24rem;
    padding: 2.5rem;
    margin: 1rem 0 1rem 0 !important;
    font-size: $font-size-rem;
    color: $headline-dark;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 2px solid $primary;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    background-color: $white;
    border-radius: $border-radius;

    &:focus {
        outline: none;
        border-color: $hover;
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-top-width: 0;
        border-bottom-color: $primary;
        top: -2.2rem;
        left: 2.1rem;
        border-width: 11px;
    }

    &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        top: -1.9rem;
        left: 3.2rem;
        content: " ";
        margin-left: -1rem;
        border-top-width: 0;
        border-color: transparent;
        border-bottom-color: $white;
        border-width: 1rem;
    }

    .btn {
        margin-bottom: 1rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.show {
        display: inline-block;
    }
}

    .dropdown--right {
        left: auto;
        right: 0;

        &:before {
            left: auto;
            right: 2.1rem;
        }

        &:after {
            left: auto;
            right: 2.2rem;
        }
    }

    .dropdown--center {
        left: 50%;
        transform: translate(-50%);

        &:before {
            left: 50%;
            transform: translate(-50%);
            right: auto;
        }

        &:after {
            left: 50%;
            right: auto;
            transform: translate(-50%);
            margin-left: 0;
        }
    }

    .dropdown--medium {
        width: 40rem;
    }

    .dropdown--large {
        width: 51rem;
    }

    .dropdown__close {
        margin-bottom: 1.6rem;
        text-align: right;
        svg {
            margin-left: .8rem;
            position: relative;
            top: .2rem;
        }
    }

