.table-data {
    margin-bottom: 2rem;
    display: table;
    width: 100%;

    @media (min-width: $screen-md-min) {
        margin-bottom: .5rem;
    }
}

.table-data tr td {
    width: 100%;
    display: block;

    @media (min-width: $screen-md-min) {
        width: 33%;
        display: table-cell;
        vertical-align: top;
        padding: .5rem 0;
    }
}

.table-data tr {
    margin-bottom: .5rem;
}

.table-data td:first-of-type {
    font-weight: bold;
}

.table-data td .text-right {
    @media (max-width: $screen-md-max) {
        text-align: left;
    }
}

.table-data tr:not(:last-child) {
    @media (max-width: $screen-sm-max) {
        display: block;
        margin-bottom: 2rem;
    }
}
