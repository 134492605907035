.square-grid {
	display: flex;
	list-style-type: none;
	padding: 0;
	flex-direction: column;
	margin: 0 0 3rem;

	li {
		display: flex;
		flex-direction: column;
		margin: 0 0 1rem;
		cursor: pointer;

		.btn {
			padding: .5rem;
			display: flex;
		}

		a {
			display: block;
			text-align: center;
			color: $body-color;

			&:hover {
				text-decoration: none;
			}

			&:focus {
				outline: initial;
			}
		}
		p {
			margin-top: 0;
			text-align: center;
		}
		img {
			width: 56%;
			margin-top: .5rem;
		}
		&:hover {
			border-color: $primary;
		}
	}

	.btn {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.btn__icon {
		display: inline-block;
		margin-right: 1rem;
	}

	.btn__text {
		font-family: $font-family-sans-serif;
		color: $secondary;
	}

	@media (min-width: $screen-sm-min) {
		margin: 0rem -0.5rem 2rem;
		flex-wrap: wrap;
		flex-direction: row;

		li {
			flex-basis: calc(50% - 2rem);
			margin: 0 .5rem 1rem;

			.btn__text {
				flex: 1;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		flex-direction: row;
		flex: 1;

		li {
			flex-basis: inherit;
			flex-grow: 1;

			.btn {
				flex-direction: column;
				height: 100%;
				padding: .7rem;

				&__icon {
					margin: .5rem 0rem .5rem;
				}

				&__text {
					flex: auto;
					width: 100%;
				}
			}
		}
	}

	@media (min-width: $screen-lg-min) {
		margin: 0 -1.3rem 3rem;

		li {
			margin: 0 1.3rem 3rem;

			.btn {
				padding: 1.5rem;

				&__text {
					padding-top: 1rem;
				}
			}
		}
	}

	@media (min-width: $screen-xl-min) {
		flex-wrap: nowrap;
	}
}

.square-grid--rounded {
	.btn {
		border-radius: $border-radius;
	}
}

.square-grid--no-shadow {
	.btn {
		box-shadow: 0 0 0 0 transparent;
	}
}