.btn-group {
    margin-top: 2rem;
    .btn {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
}
    .btn-group--right {
        display: flex;
        justify-content: flex-end;
    }
    .btn-group--spread {
        display: flex;
        justify-content: space-between;
        
        @media (max-width: $screen-xs-max) {
            flex-flow: row wrap;
            .btn {
                width: 100%;
                margin: 0 0 1rem 0;
            }
        }
    }
    .btn-group--mobile-full {
        @media (max-width: $screen-xs-max) {
            display: flex;
            flex-flow: row wrap;
            .btn {
                width: 100%;
                margin: 0 0 1rem 0;
            }
        }
    }