table {
    width: 100%;
    border-collapse: collapse;
}

.table--bordered {
    margin-bottom: 4rem;

    thead {
        tr {
            th {
                padding: .8rem 0;
                text-align: left;
            }
        }
    }

    tbody {
        tr {
            vertical-align: top;

            &:last-child {
                border-bottom: .1rem solid $body-color;
            }
        }

        .tr--subject {
            padding-top: .8rem;
        }

        td {
            width: 32%;
            padding: 1.6rem 0;
            border-top: .1rem solid $body-color;

        }

        .td--no-border {
            border-top: .1rem solid transparent;

        }

        .td--subject {
            padding-top: 3.2rem;

        }

    }
}

.table--bordered-mobile {
    @media (min-width: $screen-md-min) {
        display: none;
    }

    tbody {
        td {
            width: 49%;
        }
    }
}

.table--bordered-desktop {

    @media (max-width: $screen-sm-max) {
        display: none;
    }

    tbody {
        td {
            width: 32%;
        }
    }
}

