.alert-error {
    padding-left: 40px;
    background-color: $secondary-light3;
    color: $headline-dark;
    border: 0;
    border-radius: $border-radius-half;

    ul li {
        padding-bottom: 10px;
        &:last-child {
            padding-bottom: 0px;
        }
        .svg-icon {
            position: absolute;
            left: 16px;
        }
    }
}
    .alert-error__icon {
        @media (min-width: $screen-xs-max) {
            position: absolute;
        }
    }
    .alert-error__message {
        @media (min-width: $screen-xs-max) {
            display: block;
            margin-left: 30px;
        }
    }
    .alert-error--invert {
        background-color: $white;
    }
    .alert-error--right {
        text-align: right;
    }