@import 'settings/variables';

.modal__close-bottom {
    span {
        text-decoration: underline;
    }
    svg {
        margin-left: 0.8rem;
        vertical-align: top;
        position: relative;
        top: 0.7rem;
    }

    @media (min-width: $screen-sm-min) {
        display: none;
    }
}
