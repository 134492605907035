@import '_variables.scss';

%arrow-bordered {
    &:before,
    &:after {
        content: ' ';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-top-width: 0;
        border-color: transparent;
    }
}
%arrow-left {
    &:before {
        left: 2rem;
    }
    &:after {
        left: 2.2rem;
    }
}
%arrow-center {
    &:before {
        left: 50%;
        transform: translateX(-50%);
    }
    &:after {
        left: 50%;
        transform: translateX(-50%);
    }
}
%arrow-bottom {
    &:before {
        bottom: 2rem;
    }
    &:after {
        bottom: 2.2rem;
    }
}

%arrow-top-left {
    @extend %arrow-bordered;
    @extend %arrow-left;
    &:before {
        top: -1.4rem;
        border-width: 0 8px 12px 8px;
        border-color: transparent transparent $primary transparent;
    }
    &:after {
        top: -1rem;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent $white transparent;
    }
}
%arrow-bottom-left {
    @extend %arrow-bordered;
    @extend %arrow-left;
    &:before {
        bottom: -1.4rem;
        border-width: 12px 8px 0 8px;
        border-color: $primary transparent transparent transparent;
    }
    &:after {
        bottom: -1rem;
        border-width: 10px 6px 0 6px;
        border-color: $white transparent transparent transparent;
    }
}
%arrow-bottom-center {
    @extend %arrow-bordered;
    @extend %arrow-center;
    &:before {
        bottom: -1.4rem;
        border-width: 12px 8px 0 8px;
        border-color: $primary transparent transparent transparent;
    }
    &:after {
        bottom: -1rem;
        border-width: 10px 6px 0 6px;
        border-color: $white transparent transparent transparent;
    }
}
%arrow-left-bottom {
    @extend %arrow-bordered;
    @extend %arrow-bottom;
    &:before {
        left: -1.4rem;
        border-width: 8px 12px 8px 0;
        border-color: transparent $primary transparent transparent;
    }
    &:after {
        left: -1rem;
        border-width: 6px 10px 6px 0;
        border-color: transparent $white transparent transparent;
    }
}
