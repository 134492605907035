h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    font-family: $headings-font-family;
    font-weight: 700;
    line-height: 1.3;
    color: $headline-dark;
}

h1, .h1 {
    margin-bottom: 1em;
    font-size: $h1-font-size;
    @media (max-width: $screen-sm-max) {
        font-size: $h1-font-size-small;
        margin-bottom: 1.2rem;
    }
}
    .h1--upper {
        text-transform: uppercase;
    }

h2, .h2 {
    margin-bottom: 0.8em;
    font-size: $h2-font-size;

    @media (max-width: $screen-sm-max) {
        font-size: $h2-font-size-small;
    }

    small {
        font-size: 60%;
        color: $secondary-dark;
    }
}

h3, .h3 {
    margin-bottom: 0.5em;
    font-size: $h3-font-size;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: $screen-sm-max) {
        font-size: $h3-font-size-small;
    }
}

h4, .h4 {
    font-size: $h4-font-size;
    @media (max-width: $screen-sm-max) {
        font-size: $h4-font-size-small;
    }
}

p {
    margin-top: 0;
    margin-bottom: 1.6rem;
    font-size: $font-size-rem;
    &:last-child {
        margin-bottom: 0;
    }
    @media (max-width: $screen-sm-max) {
        font-size: $font-size-small-rem;
    }
}

b,
strong {
    font-family: $font-family-sans-medium;
    font-weight: normal;
}

sup {
    font-size: 60%;
    margin-left: .5rem;
    line-height: 0;
    top: 1.36rem;
    vertical-align: top;
}

.small {
    font-size: 85%;
}