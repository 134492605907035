@font-face {
    font-family: 'inshared_sans_regular';
    src: url('/assets/fonts/InSharedSans-Regular-V05.woff') format('woff');
    font-display: fallback;
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'inshared_sans_medium';
    src: url('/assets/fonts/insharedsans-medium-webfont.woff') format('woff');
    font-display: fallback;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inshared_sans_demibold';
    src: url('/assets/fonts/insharedsans-demibold-webfont.woff') format('woff');
    font-display: fallback;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'inshared_sans_bold';
    src: url('/assets/fonts/InSharedSans-Bold-V05_2.woff') format('woff');
    font-display: fallback;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'casino_hand';
    src: url('/assets/fonts/Casino_Hand-webfont.woff') format('woff');
    font-display: fallback;
    font-weight: normal;
    font-style: normal;
}
