@import 'settings/variables';

// modal__wrapper is added by modal-content directive,
// modal__wrapper is the host class of the modal component.
.modal__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

// modal__content is added by the modal-content directive,
// these style override the default modal__content styling.
.modal__content,
.modal__content.fullscreen {
    display: block;
    margin: 0 -2.4rem;
    padding: 0 2.4rem;
    max-height: none !important;
    overflow: auto !important;
    min-height: auto !important;
    height: auto !important;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    @media (min-width: $screen-md-min) {
        max-height: 65vh !important;
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
    }
}
.modal__close-btn {
    @extend .link-secondary;
    top: 1.8rem;
    right: 2.3rem;
    span {
        text-decoration: underline;
    }
    svg {
        margin-left: 0.8rem;
        vertical-align: top;
        position: relative;
        top: 0.7rem;
    }
}
