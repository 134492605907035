@import 'settings/variables';

// this is the host class of modal-container
.temp-modal {
    border-radius: 0;
    box-shadow: 0 1rem 2rem 0 $secondary-dark;
    background-color: $white;
    min-width: auto;

    @media (min-width: $screen-md-min) {
        min-width: 65rem;
        border-radius: $border-radius;
    }
}

// fullscreen (desktop) modal has no border radius
.cdk-global-scrollblock-fullscreen .temp-modal {
    border-radius: 0 !important;
}
