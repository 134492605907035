.alert-danger {
    border-radius: $border-radius-half;
    background-color: $primary;
    border-color: $primary-dark;
    border-width: $border-width;
    
    h5,
    p {
        color: $white;
        padding-left: 50px;
    }

    .svg-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        fill: $white;
    }
}
