.centered-content {
    display: flex;
    flex-direction: column;
}

    .centered-content--vertical {
        justify-content: center;
        height: 100%;
    }

    .centered-content--vertical-row {
        display:flex;
        align-items: center;
    }

    .centered-content--horizontal {
        align-items: center;
    }
