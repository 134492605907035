.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gap / 2;
    padding-right: $grid-gap / 2;
    & + .container {
        padding-top: 5rem;
    }
}

.container--centered {
    display: flex;
    align-items: center;
    flex-direction: column;
}

// use wrapper mainly for page size grids
.wrapper {
    display: flex;
    flex-wrap: wrap;

    @supports(display: grid) {
        display: grid;
        grid-template-columns: repeat(4, minmax(1px, 1fr) );
        grid-column-gap: 2rem;
        grid-auto-flow: dense;
        margin-right: 0;
        margin-left: 0;

        @media (min-width: $screen-md-min) {
            grid-template-columns: repeat(12, minmax(1px, 1fr) );
            grid-column-gap: $grid-gap;
        }
    }
}
    .wrapper--rowspace {
        grid-row-gap: 2rem;
    }

@media (min-width: $screen-md-min) {
    .container {
        width: 75rem;
    }
        .container--small {
            padding-left: 4rem;
            padding-right: 4rem;
        }

    .hidden-mobile {
        display: block;
    }
    .hidden-desktop {
        display: none;
    }
}

@media (min-width: $screen-lg-min) {
    .container {
        width: 96rem;
    }
        .container--small {
            padding-left: 10rem;
            padding-right: 10rem;
        }
}

@media (min-width: $screen-xl-min) {
    .container {
        width: 114rem;
    }
        .container--small {
            padding-left: 18rem;
            padding-right: 18rem;
        }
}

// Grid supports large and small devices => flex should also support both (small devices that do not support grid?)
// IE11 doesn't support media query in loop?, so we add flex here and overrule it later if the browser supports grid
@media screen and (max-width: $screen-sm-max) {
    @for $counter from 1 through 12 {
        .col-#{$counter} {
            width: 100%;
        }
    }
}

@media screen and (min-width: $screen-md-min) {
    @for $counter from 1 through 12 {
        $multiplier: $counter / 12;
        .col-#{$counter} {
            // removed flex-basis, as IE11 didn't support the flex shorthand, and we use width as well
            // TODO: Deze berekening klopt helemaal niet... col-12 is veel groter dan col-5 + col-7 bijvoorbeeld.
            width: calc(100% * #{$multiplier} - #{$grid-gap} * (1 - #{$multiplier}));
        }
    }
}

@for $col from 1 through 12 {
    $multiplier: $col / 12;
    .col-#{$col} {
        padding: 0 $grid-gap*.5;

        @supports(display: grid) {
            display: block;
            grid-column: span 4;
            width: 100%;
            padding: 0;

            .wrapper--flex & {
                display: flex;
            }

            &.hidden-mobile {
                display: none;
            }
            &.hidden-desktop {
                display: block;
            }

            @media (min-width: $screen-md-min) {
                grid-column: span #{$col};

                &.hidden-mobile {
                    display: block;
                }
                &.hidden-desktop {
                    display: none;
                }
            }
        }
    }

    .col-#{$col}-last {
        flex: 1 1 $multiplier;

        @supports(display: grid) {
            grid-column: span #{$col}/5;

            @media (min-width: $screen-md-min) {
                grid-column: span #{$col}/13;
            }
        }
    }
}

@for $col from 1 through 4 {
    .col-#{$col}-xs {
        @supports(display: grid) {
            @media (max-width: $screen-sm-max) {
                grid-column: span #{$col};
            }
        }
    }
}

.col-push-md {
    @media (min-width: $screen-md-min) {
        &.col-4 {
            grid-column: 9/13;
        }
        &.col-6 {
            grid-column: 7/13;
        }
        &.col-7 {
            grid-column: 6/13;
        }
    }
}
.col-pull-md {
    @media (min-width: $screen-md-min) {
        &.col-5 {
            grid-column: 1/6;
        }
        &.col-6 {
            grid-column: 1/7;
        }
        &.col-8 {
            grid-column: 1/9;
        }
    }
}
