.app-price {
    font-size: $font-size-xxxxl;
    font-family: $headings-font-family;
    color: $headline-dark;
    display: inline-block;

    span {
        margin-left: 3px;
        font-size: 0.6em;
        vertical-align: top;
    }
}
.app-price--small {
    font-size: 2.9rem;
}
