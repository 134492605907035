.section {
	&:first-child {
		padding-top: 1.6rem;
	}
}
	.section--grey {
		background-color: $secondary-light3;
	}
	.section--white {
		background-color: $white;
	}
	.section--pink {
		background-color: $primary;
	}
	.section--sticky {
		position: sticky !important;
		top: 0;
		z-index: 100;
		background-color: $white;
		backface-visibility: hidden;
	}

	.section--vertical-spacing {
		padding: 2rem 0;
	}

	.section--border {
		position: relative;
		&:after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			border-bottom: 2px solid $primary;
		}
	}

	.section--bordered {
		position: relative;

		&:before,
		&:after {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			width: 100%;
			height: 2px;
			border-bottom: 2px solid $primary;
		}

		&:before {
			top: -.2rem;
		}

		&:after {
			bottom: -.2rem;
		}
	}

	.section--content {
		padding-top: 3rem;
		padding-bottom: 3rem;
		@media (min-width: $screen-lg-min) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}
	}
	
	.section__content--padded-top {
		padding-top: 3rem;

		@media (min-width: $screen-lg-min) {
			padding-top: 5rem;
		}
	}

	.section__content--padded-bottom {
		padding-bottom: 3rem;

		@media (min-width: $screen-lg-min) {
			padding-bottom: 5rem;
		}
	}

	.section--divider {
		position: relative;
		background: linear-gradient($white 50%, $secondary-light3 50%);
		&:after {
			content: '';
			position: absolute;
			top: calc(50% + -.3rem);
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			height: 2px;
			border-bottom: 2px solid $primary;
		}
	}

	.section--mobile {
		display: block;

		@media (min-width: $screen-md-min) {
			display: none;
		}
	}
	.section--desktop {
		display: none;

		@media (min-width: $screen-md-min) {
			display: block;
		}
	}
