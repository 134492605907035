.alert-warning {
    border-radius: $border-radius-half;
    border-color: $hover;
    
    h5,
    p {
        color: $hover;
        padding-left: 50px;
    }

    .svg-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        fill: $hover;
    }
}
