@import '@angular/cdk/overlay-prebuilt.css';
@import 'settings/variables';

// this is added to the body when modal service is invoked
.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: hidden;

    @media (min-width: $screen-md-min) {
        overflow-y: scroll;
    }
}

// this is added to the body when modal service is invoked,
// only when modal is full screen or mobile.
.cdk-global-scrollblock-fullscreen {
    position: fixed;
    width: 100%;
    overflow-y: hidden;
}

// this is added to the body when modal service is invoked
.cdk-overlay-pane {
    max-width: none;
    max-height: none;
    pointer-events: auto;
    width: 100vw;
    height: 100vh;
    position: static;
    max-height: 100% !important;

    @media (min-width: $screen-md-min) {
        width: auto;
        height: auto;
    }
}
